import React from "react"
import {
  ModalFeature,
  ModalTitle,
  Text,
} from "@thedgbrt/logology-ui-components"
import { trackSegmentEvent } from "../../../helpers/segment"
import { trackAmplitude } from "../../../helpers/amplitude"

const Package1ModalFeatures = ({ planType }) => {
  const trackSampleFileDownload = e => {
    trackSegmentEvent("track", "Download sample package", { type: planType })
    trackAmplitude("Download sample package", { type: planType })
  }

  return (
    <>
      <ModalTitle
        title="Starter package details"
        subtitle={
          <>
            Download this{" "}
            <a
              href="/files/logology_sample_starter_logo_package.zip"
              onClick={trackSampleFileDownload}
            >
              sample package
            </a>{" "}
            to see exactly what files you will receive.
          </>
        }
      />

      <ModalFeature
        title="Logo from our catalog"
        content={
          <Text italic>
            Your logo was pre-made by a real designer, from an original idea
            that started with pen and paper.
            <br />
            Your design is simple and easy to remember. It looks perfect from
            tiny app icon to huge billboards, in color and black and white.
          </Text>
        }
      />

      <ModalFeature
        title="PNG image only"
        content={
          <Text italic>
            Your logo image file comes in three variants: white background,
            color background and transparent background.
            <br />
            It comes in a single format ready for web and digital use (PNG, with
            a resolution of 144dpi or 950x600 pixels.
            <br />
            It can be easily opened by any major drawing or presentation
            software (Photoshop, Sketch, Word, PowerPoint, etc.).
          </Text>
        }
      />

      <ModalFeature
        title="Simple brand guidelines"
        content={
          <Text italic>
            Brand guidelines give you clear instructions to make the best out of
            your logo:
            <ul>
              <li>how to use and format your logo for different use cases</li>
              <li>which font to use in your text materials</li>
              <li>
                how to find a secondary font that complements the one in your
                logo
              </li>
              <li>which colors to use</li>
            </ul>
          </Text>
        }
      />

      <ModalFeature
        title="Lifetime use &amp; modification license"
        content={
          <Text italic>
            You can use your logo for any personal or business project. The
            license gives you lifetime rights to use it in web, print, and any
            other media communication. There are{" "}
            <b>no limits on how many times you can print or display it</b>.
          </Text>
        }
      />

      <ModalFeature
        title="Lifetime customer support"
        content={
          <Text italic>
            We’re always available to help you sort through any issues or
            questions you might have about your purchase. Just email us at{" "}
            <a href="mailto:support@logology.co">support@logology.co</a>.
          </Text>
        }
      />
    </>
  )
}

export default Package1ModalFeatures
