/* eslint-disable */
import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { navigate } from "gatsby"
import {
  Button,
  Grid5PricingPlans,
  ModalGrid,
} from "@thedgbrt/logology-ui-components"
import Package1ModalFeatures from "../Proposal/ProposalBuy/Package1ModalFeatures"
import Package2ModalFeatures from "../Proposal/ProposalBuy/Package2ModalFeatures"
import Package3ModalFeatures from "../Proposal/ProposalBuy/Package3ModalFeatures"
import { trackSegmentEvent } from "../../helpers/segment"
import { trackAmplitude } from "../../helpers/amplitude"

const PricingPlans = ({ priceStart, priceGrow, priceEstablish }) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState("starter")
  const [isRendered, setIsRendered] = useState(false)

  // need to have access to dom to use the modal
  // so we check we're on the client side and not SSR
  useEffect(() => {
    setIsRendered(true)
  }, [])

  const handleClickDetails = (e, planType) => {
    e.preventDefault()
    setSelectedPlan(planType)
    setShowDetailsModal(true)
    trackSegmentEvent("track", "View plan details", { type: planType })
    trackAmplitude("View plan details", { type: planType })
  }

  const trackSampleFileDownload = (e, planType) => {
    trackSegmentEvent("track", "Download sample package", { type: planType })
    trackAmplitude("Download sample package", { type: planType })
  }

  const handleClickStart = (e, planType) => {
    trackSegmentEvent("track", "Get started plan", { type: planType })
    trackAmplitude("Get started plan", { type: planType })
    navigate("/questionnaire/")
  }

  const renderModalContents = () => {
    if (selectedPlan === "starter")
      return <Package1ModalFeatures planType={selectedPlan} />
    else if (selectedPlan === "growth")
      return <Package2ModalFeatures planType={selectedPlan} />
    else return <Package3ModalFeatures planType={selectedPlan} />
  }

  return (
    <>
      <Grid5PricingPlans
        colTitles={["Start", "Grow", "Establish"]}
        colDescriptions={[
          "Look credible as you start working on your idea.",
          "All the resources to make your startup look perfect.",
          "A custom design to stand out even more.",
        ]}
        colPrices={[priceStart, priceGrow, priceEstablish]}
        colButtons={[
          <Button alternative onClick={e => handleClickStart("starter")}>
            get started →
          </Button>,
          <Button
            alternative
            onClick={e => handleClickStart("growth")}
            inverted
          >
            get started →
          </Button>,
          <Button alternative onClick={e => handleClickStart("establish")}>
            get started →
          </Button>,
        ]}
        colBottomItems={[
          [
            <p>
              <a href="#" onClick={e => handleClickDetails(e, "starter")}>
                view details
              </a>
              &nbsp;&nbsp;
              <a
                href="/files/logology_sample_starter_logo_package.zip"
                onClick={e => trackSampleFileDownload("starter")}
              >
                download sample
              </a>
            </p>,
          ],
          [
            <p>
              <a href="#" onClick={e => handleClickDetails(e, "growth")}>
                view details
              </a>
              &nbsp;&nbsp;
              <a
                href="/files/logology_sample_growth_logo_package.zip"
                onClick={e => trackSampleFileDownload("growth")}
              >
                download sample
              </a>
            </p>,
          ],
          [
            <p>
              <a href="#" onClick={e => handleClickDetails(e, "establish")}>
                view details
              </a>
              &nbsp;&nbsp;
              <a
                href="/files/logology_sample_establish_logo_package.zip"
                onClick={e => trackSampleFileDownload("establish")}
              >
                download sample
              </a>
            </p>,
          ],
        ]}
        colSubtexts={["instant email delivery", "instant email delivery", ""]}
        colSubtextTypes={["email", "email", "none"]}
        rowValues={[
          [
            "<p>logo from our catalog</p>",
            "<p>PNG image only</p>",
            "<p>lifetime use & modification license</p>",
            "<p>basic brand guidelines</p>",
            "",
            "",
          ],
          [
            "<p>logo from our catalog</p>",
            "<p>PNG images <b>+ source files</b></p>",
            "<p>lifetime use & modification license</p>",
            "<p>12 color and layout variations</p>",
            "<p>basic brand guidelines</p>",
            "",
          ],
          [
            "<p>logo <b>made just for you</b></p>",
            "<p>PNG images <b>+ source files</b></p>",
            "<p>lifetime <b>exclusive rights & full copyrights transfer</b></p>",
            "<p>12 color and layout variations</p>",
            "<p>1-hour brand definition call</p>",
            "<p>add-on: full brand guidelines (starts at $599)</p>",
          ],
        ]}
      />
      {isRendered
        ? createPortal(
            <ModalGrid
              aboveAll
              closeOutside
              hidden={!showDetailsModal}
              handleClose={() => setShowDetailsModal(false)}
              type={5}
            >
              {renderModalContents()}
            </ModalGrid>,
            document.body
          )
        : null}
    </>
  )
}

export default PricingPlans
