import React from "react"
import {
  ModalFeature,
  ModalTitle,
  Text,
} from "@thedgbrt/logology-ui-components"
import { trackSegmentEvent } from "../../../helpers/segment"
import { trackAmplitude } from "../../../helpers/amplitude"

const Package3ModalFeatures = ({ planType }) => {
  const trackSampleFileDownload = e => {
    trackSegmentEvent("track", "Download sample package", { type: planType })
    trackAmplitude("Download sample package", { type: planType })
  }

  return (
    <>
      <ModalTitle
        title="Establish package details"
        subtitle={
          <>
            Download this{" "}
            <a
              href="/files/logology_sample_establish_logo_package.zip"
              onClick={trackSampleFileDownload}
            >
              sample package
            </a>{" "}
            to see exactly what files you will receive.
          </>
        }
      />

      <ModalFeature
        title="Logo made just for you"
        content={
          <Text italic>
            You will get full exclusive rights to your logo.{" "}
            <b>Nobody else will ever be able to buy the same symbol.</b>
            <br />
            <br />
            You can use your logo for any personal or business project. In web,
            print, and any other media communication. There are{" "}
            <b>no limits on how many times you can print or display it</b>.
            <br />
            <br />
            By giving you <b>all source files and modification rights</b>, you
            are entitled to make updates and customizations as much as you want.
          </Text>
        }
      />

      <ModalFeature
        title="PNG images + source files (SVG)"
        content={
          <Text italic>
            Source files allow you to <b>change colors easily</b>, as well as{" "}
            <b>scale your design to any size</b> without loss of quality.
            <br />
            <br /> Your source files come in vector SVG format and can be opened
            in any major drawing application (Adobe Illustrator, Photoshop,
            Affinity Designer, Sketch, Gimp, etc.
          </Text>
        }
      />

      <ModalFeature
        title="Full brand guidelines"
        content={
          <Text italic>
            Brand guidelines give you clear instructions to make the best out of
            your logo:
            <ul>
              <li>how to use and format your logo for different use cases</li>
              <li>which font to use in your text materials</li>
              <li>
                how to find a secondary font that complements the one in your
                logo
              </li>
              <li>which colors to use</li>
              <li>
                how to best print your logo depending on your capabilities
                (regular vs PMS printing)
              </li>
            </ul>
          </Text>
        }
      />

      <ModalFeature
        title="Lifetime exclusive rights &amp; full copyrights transfer"
        content={
          <Text italic>
            You will get{" "}
            <b>full exclusivity and trademarking rights to the logo</b>. To
            guarantee the success of the trademarking process, please consult a
            lawyer first. We are not legal advisors and there are many other
            pieces that need to be taken into account beyond owning a unique
            logo design.
          </Text>
        }
      />

      <ModalFeature
        title="24 web and print image files (PNG)"
        content={
          <Text italic>
            Web and print files can be readily used on your{" "}
            <b>website, social media pages, business cards</b>, and any other
            type of documents.
            <br />
            <br />
            Every file comes in two resolutions, one for print (720DPI or
            4750x3000 pixels) and one for digital use (144dpi or 950x600
            pixels).
            <br />
            <br />
            Delivered in PNG format, they can be easily opened by any major
            drawing or presentation software (Photoshop, Sketch, Word,
            PowerPoint, etc.).
          </Text>
        }
      />

      <ModalFeature
        title="12 color and layout variations"
        content={
          <Text italic>
            We’ve prepared 12 display variations so that your brand looks good
            in every context:
            <ul>
              <li>horizontal layouts for website headers and business cards</li>
              <li>vertical layouts for presentations and packaging</li>
              <li>
                black and white versions to show a subtle mark or brand
                signature
              </li>
              <li>transparent versions for app and website developers</li>
            </ul>
          </Text>
        }
      />

      <ModalFeature
        title="1-hour brand definition call"
        content={
          <Text italic>
            Our designer will spend 1-hour with you over the phone to determine
            how to make your logo suit you even more.
            <br />
            <br />
            For example, if you want a different emotion to be conveyed through
            your symbol, or require a more unique font or color scheme.
            <br />
            <br />
            She will then create new unique design proposals for you to choose
            from. If you don't like the new designs, we will give you a full
            refund.
          </Text>
        }
      />

      <ModalFeature
        title="Unlimited color &amp; font changes"
        content={
          <Text italic>
            As your company finds its footing, you might decide that a different
            font, color, or even brand name suits you better. At any point in
            time after your purchase, we're happy to provide{" "}
            <b>updates at no additional costs</b>.
          </Text>
        }
      />

      <ModalFeature
        title="Lifetime customer support"
        content={
          <Text italic>
            We’re always available to help you sort through any issues or
            questions you might have about your purchase. Just email us at{" "}
            <a href="mailto:support@logology.co">support@logology.co</a>.
          </Text>
        }
      />
    </>
  )
}

export default Package3ModalFeatures
